export const injectMonetagScript = () => {
  if (!document.getElementById("monetag-script")) {
    const script = document.createElement("script");
    script.id = "monetag-script";
    script.type = "text/javascript";
    script.src = `https://groleegni.net/401/8831402`;

    try {
      (document.body || document.documentElement).appendChild(script);
      console.log("Monetag script injected successfully");
    } catch (e) {
      console.error("Failed to inject Monetag script:", e);
    }
  } else {
    console.log("Monetag script is already loaded.");
  }
};
