import React from "react";
import styles from "./Carousel.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { CarouselItems } from "./CarouselItems";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { movieBaseUrl } from "../../Api-config/movie";
import axios from "axios";
import not_found from "../../assets/images/movies_not_found.png";
import "./carousel.css";
import { generateTokenHandler } from "../../utils/json-web";
import { getSeasonNumber } from "../../redux/dataReducer/action";
import { useDispatch } from "react-redux";
const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${styles.slick_prev}`}>
      <svg
        onClick={onClick}
        style={{ transform: "rotate(180deg)" }}
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="24"
        viewBox="0 0 15 24"
        fill="none"
      >
        <path
          d="M2 2L12 12L2 22"
          stroke="#017bf4"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={`${className} ${styles.slick_next}`}>
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="24"
        viewBox="0 0 15 24"
        fill="none"
      >
        <path
          d="M2 2L12 12L2 22"
          stroke="#017bf4"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export const Carousel = ({ title, data, getToast, getDeviceType }) => {
  console.log("data: ", data);
  const dispatch = useDispatch();
  // Calculate the minimum number of slides to show
  var settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: Math.min(data.length, 6),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(data.length, 5),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(data.length, 4),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(data.length, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(data.length, 1),
          slidesToScroll: 1,
        },
      },
    ],
  };
  let slidesToShow = Math.min(data.length, 7);
  // Update the settings with the calculated value
  const updatedSettings = {
    ...settings,
    slidesToShow,
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  // getting token
  const authUser = localStorage.getItem("authUser");

  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(": ");
      var key = parts[0]?.trim();
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  const handleVideoClick = async (videoId, seasons, isSeries) => {
    if (isSeries) {
      dispatch(getSeasonNumber(seasons));
    } else {
      dispatch(getSeasonNumber(1));
    }
    const slug = await generateTokenHandler();
    let headers = {
      authorization: authUser,
      devicetype: getDeviceType(),
      slug: slug,
    };

    console.log(headers.authorization);
    console.log(headers.devicetype);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    console.log("headers", headers);

    let data;
    sessionStorage.setItem("isSeries", isSeries);

    if (isSeries) {
      console.log("season,,,,,,", seasons);

      data = {
        videoId,
        trailer: false,
        seasonNumber: parseInt(seasons),
        banner: false,
      };
    } else {
      data = {
        videoId,
        trailer: false,
        banner: false,
      };
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `videoClickOrTrailerPlayed`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  return (
    <div className={styles.carousel_container}>
      <h2>{title}</h2>
      {data?.length === 0 ? (
        <div className={styles.not_found_container}>
          <img src={not_found} alt="Not Found" />
          <h6>No Results</h6>
        </div>
      ) : (
        <Slider {...updatedSettings} className={styles.carousel_box}>
          {data?.map((el, i) => (
            <NavLink
              style={{
                textDecoration: "none",
                width: "100%",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() =>
                handleVideoClick(
                  el?.videoId,
                  el?.seasons?.seasonNumber,
                  el?.isSeries
                )
              }
              key={i}
              to={`/details/${el?.videoId}`}
            >
              <CarouselItems {...el} />
            </NavLink>
          ))}
        </Slider>
      )}
    </div>
  );
};
