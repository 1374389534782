import React, { useEffect, useRef, useState } from "react";
import styles from "./MediaPlayer.module.css";
//Ashish changed next line for PPE
import { movieBaseUrl, syncBaseUrl, syncPort } from "../../Api-config/movie";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAudioDataSuccess } from "../../redux/sinkReducer/action";
import { useNavigate } from "react-router-dom";
import ReactDRMPlayer from "@gumlet/react-drm-player";
import crypto from "crypto-js";
import headphones from "../../assets/new-images/headphone.png";
import { Controls } from "./Controls";
import { generateTokenHandler, gumletCollectionId } from "../../utils/json-web";
import playIcon from "../../assets/new-images/mediaPlayer/play.svg";
import pauseIcon from "../../assets/new-images/mediaPlayer/pause.svg";
import forwardIcon from "../../assets/new-images/mediaPlayer/forward.svg";
import backwardIcon from "../../assets/new-images/mediaPlayer/backward.svg";
import { FullScreenLoader } from "../../components/fullScreen loader/FullScreenLoader";
import { checkPaymentStatusForEpisodesInPPE } from "../../utils/checkContentPurchaseStatus";

export const MediaPlayer = ({
  getToast,
  asset_id,
  handleSelectEpisode,
  getDeviceType,
}) => {
  console.log("assetId:Top of the  ", asset_id);
  const [authToken, setAuthToken] = useState(null);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [isSeries, setIsSeries] = useState(false);
  const { t } = useTranslation();
  const [playerSrc, setPlayerSrc] = useState(null);
  const [assetId, setAssestId] = useState(asset_id);

  const [loadingForVideo, setLoadingForVideo] = useState(false);
  const [price, setPrice] = useState(null);

  // store the payment status
  const [paymentStatus, setPaymentStatus] = useState(null);
  // save episodesList here
  const episodeNumber = sessionStorage.getItem("episodeNumber");
  const [episodesList, setEpisodesList] = useState([]);

  // episode selected
  const [selectedEpisode, setSelectedEpisode] = useState(
    parseInt(episodeNumber) ? parseInt(episodeNumber) : 1
  );

  const dispatch = useDispatch();
  const [transactionId, setTransactionId] = useState("");
  // token
  const token = localStorage.getItem("authUser");
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  // you will get the value for yes or no
  const primaryDevice =
    window.primaryDevice || sessionStorage.getItem("primaryDevice") || "no";
  // viewerId getting from app
  console.log("primaryDevice: ", primaryDevice);
  const viewerId = window.viewerId || localStorage.getItem("viewerId");
  console.log("viewerId: ", viewerId);
  const videoId = window.videoDetails || sessionStorage.getItem("videoDetails");
  console.log("videoId: ", videoId);
  const location = JSON.parse(localStorage.getItem("location")) || {
    latitude: "20.59",
    longitude: "78.96",
  };
  // getting the value for which device user has payed
  const paymentDetails = JSON.parse(sessionStorage.getItem("paymentDetails"));
  const [showPlayBtn, setShowPlayButton] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const videoRef = useRef(null);
  const controlTimeoutRef = useRef(null);
  const bluetoothStatus =
    localStorage.getItem("bluetoothConnected") == "true"
      ? "Connected"
      : "Disconnected";

  // getting the scripts
  const main = localStorage.getItem("main");
  const expms = localStorage.getItem("expms");
  const mscore = localStorage.getItem("mscore");
  const top = localStorage.getItem("top");
  const halliv = localStorage.getItem("halliv");
  const indexfn = localStorage.getItem("indexfn");

  // getting the viewerShipId here
  let viewerShipId =
    window.viewerShipId || sessionStorage.getItem("viewerShipId");
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  const [widevineLicenseURI, setWideWineLicenseURI] = useState("");

  // getting the audioUrl and assestId from reducer You can use the audio Url It will be an string
  const { audioUrl, subTitle } = useSelector((store) => store.sinkReducer);
  console.log("audioUrl: ", audioUrl);
  // const [seasonNumber, setSeasonNumber] = useState(null);
  const [seasonNumber, setSeasonNumber] = useState(
    parseInt(sessionStorage.getItem("seasonNumber"))
      ? parseInt(sessionStorage.getItem("seasonNumber"))
      : null
  );

  // Custom function to parse non-standard JSON-like string
  function parseCustomJsonString(inputString = "") {
    if (!inputString || typeof inputString != "string") {
      return;
    }
    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      // var parts = pair.split(": ");
      // var key = parts[0].trim();
      // var value = parts[1]?.trim().replace(/"|}/g, "");
      var parts = pair.split(/:\s*/);
      var key = parts[0]?.trim()?.replace(/"|'/g, "");
      var value = parts[1]?.trim().replace(/"/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }
  // function getDeviceType() {
  //   if (userAgent.includes("mobile") || userAgent.includes("iphone")) {
  //     return "mobile";
  //   } else if (
  //     userAgent.includes("windows") ||
  //     userAgent.includes("win64") ||
  //     userAgent.includes("win32") ||
  //     userAgent.includes("macos")
  //   ) {
  //     return "laptop";
  //   } else {
  //     return "tv";
  //   }
  // }

  const generateAuthToken = async (asset_id) => {
    const widevineProxy =
      "https://widevine.gumlet.com/licence/6582d83ba2ae58fb81de996b";

    const proxySecret = "e5b2e50658aaa3b66bd95d77bc556564";

    const proxyUrl = `${widevineProxy}/${asset_id}`;
    const tokenLifetime = 30; // seconds

    const queryparams = {
      expires: Math.round(Date.now() / 1000) + tokenLifetime,
    };

    const stringForTokenGeneration = `${proxyUrl.slice(
      35
    )}?${new URLSearchParams(queryparams).toString()}`;

    const signature = crypto
      .HmacSHA1(stringForTokenGeneration, proxySecret)
      .toString(crypto.enc.Hex);

    const authToken = signature;
    const signedProxyUrl = `${proxyUrl}?${new URLSearchParams({
      ...queryparams,
      token: authToken,
    }).toString()}`;

    console.log("Auth Token:", authToken);
    console.log("Signed Licence Server URL:", signedProxyUrl);

    // Set the video source with the signed URL
    const videoElement = document.getElementById("videoPlayer");

    videoElement.widevineLicenseURI = signedProxyUrl;
    videoElement.src = `https://video.gumlet.io/658414dd064278d81eef108a/${asset_id}/main.m3u8`;
    // videoElement.play();
  };

  useEffect(() => {
    const script = document.createElement("script");
    // adding scripts to document
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");
    const script3 = document.createElement("script");
    const script4 = document.createElement("script");
    const script5 = document.createElement("script");
    const script6 = document.createElement("script");

    script1.type = "text/javascript";
    script2.type = "text/javascript";
    script3.type = "text/javascript";
    script4.type = "text/javascript";
    script5.type = "text/javascript";
    script6.type = "text/javascript";

    // console.log("main: ", main);
    // script1.innerText = main;
    // script2.innerText = halliv;
    // script3.innerText = expms;
    // script4.innerText = mscore;
    // script5.innerText = top;
    // script6.innerText = indexfn;

    // document.body.appendChild(script6);
    // document.body.appendChild(script5);
    // document.body.appendChild(script4);
    // document.body.appendChild(script3);
    // document.body.appendChild(script2);
    // document.body.appendChild(script1);

    // if primary device is yes then only need to show the gumlet player else need to show the html videoplayer
    if (primaryDevice == "yes") {
      script.src =
        "https://cdn.gumlytics.com/insights/1.1/gumlet-insights.min.js";
      script.async = true;
      document.body.appendChild(script);
    }

    // PPE code
    if (primaryDevice == "yes") {
      console.log("about to initialize ppe");
      getSinkInitForPpePrimaryDevice();
      console.log("PPE initialized on Primary Device");
    } else {
      console.log("about to initialize ppe");
      getSinkInitForPpeNonPrimaryDevice();
      console.log("PPE initialized on this device");
    }

    return () => {
      console.log("unmount called..disconnecting socket...");
      window.disconnectSocketInstance();
    };
    // get the device details from app
  }, []);

  useEffect(() => {
    // generating Widewine licence Url

    getEpisodes();
    // generateAuthToken();
    getPaymentStatus();
    return () => {
      if (controlTimeoutRef.current) {
        clearTimeout(controlTimeoutRef.current);
      }
    };
  }, [selectedEpisode]);

  useEffect(() => {
    console.log("AssestId changes", assetId);
    const generateAuthToken = async () => {
      const widevineProxy = `https://widevine.gumlet.com/licence/${gumletCollectionId}`;
      const proxySecret = "e5b2e50658aaa3b66bd95d77bc556564";
      const proxyUrl = `${widevineProxy}/${assetId}`;
      const tokenLifetime = 30; // seconds

      const queryparams = {
        expires: Math.round(Date.now() / 1000) + tokenLifetime,
      };

      const stringForTokenGeneration = `${proxyUrl.slice(
        35
      )}?${new URLSearchParams(queryparams).toString()}`;

      const signature = crypto
        .HmacSHA1(stringForTokenGeneration, proxySecret)
        .toString(crypto.enc.Hex);

      const authToken = signature;
      const signedProxyUrl = `${proxyUrl}?${new URLSearchParams({
        ...queryparams,
        token: authToken,
      }).toString()}`;

      console.log("Auth Token:", authToken);
      console.log("Signed Licence Server URL:", signedProxyUrl);
      setWideWineLicenseURI(signedProxyUrl);
      // Set the playerSrc with the signed URL
      setPlayerSrc(
        `https://video.gumlet.io/${gumletCollectionId}/${assetId}/main.m3u8`
      );

      setLoadingForVideo(true);
      setTimeout(() => {
        setLoadingForVideo(false);
      }, 1000);

      // Optionally, if you want to set the video source directly in the video element
      // videoElement.src = `https://video.gumlet.io/658414dd064278d81eef108a/${assetId}/main.m3u8`;
      // videoElement.src = signedProxyUrl;
    };

    generateAuthToken();

    // document.getElementById("play_button")?.click();
    // setTimeout(() => {
    //   document.getElementById("pause_button")?.click();
    // }, 1000);

    // auto play handler
    window.autoPlayHandler = function () {
      console.log("Calling auto handlet function");
      if (document.getElementById("videoPlayer")) {
        document.getElementById("videoPlayer").play();

        setTimeout(() => {
          document.getElementById("videoPlayer").pause();
        }, 2000);
      }
    };

    // code for play and pause video for desktop only
    const videoPlayer = document.getElementById("videoPlayer");

    if (videoPlayer) {
      videoPlayer.addEventListener("play", () => {
        videoPlayer?.pause();
        window.onPlayMethod();
      });
      videoPlayer.addEventListener("pause", () => {
        videoPlayer?.play();
        window.onPauseMethod();
      });
      videoPlayer.addEventListener("forward", () => {
        window.OnForwardMethod();
      });
      videoPlayer.addEventListener("backward", () => {
        window.onBackwardMethod();
      });
    }
    setTimeout(() => {
      setShowControls(false);
    }, 3000);

    window.flutterMessageHandler = function (message) {
      console.log("Message from Flutter: ", message);

      if (message === "play") {
        // document.getElementById("play_button")?.click();
        console.log("simulate the play button click using remote btn");
        if (window.onPlayMethod) {
          console.log("get the onPlayMethod");
          window.onPlayMethod();
        }
        console.log("simulate the play button click using remote btn");
      } else if (message === "pause") {
        //        document.getElementById("videoPlayer")?.pause();
        console.log("simulate the pause button click using remote btn");
        if (window.onPauseMethod) {
          console.log("get the onPauseMethod");
          window.onPauseMethod();
        }
        // document.getElementById("pause_button")?.click();
      } else if (message === "forward") {
        console.log("get the forward");
        window.OnForwardMethod();
        // document.getElementById("videoPlayer").currentTime += 5; // Move forward by 10 seconds
      } else if (message === "backward") {
        console.log("get the backward");
        // document.getElementById("videoPlayer").currentTime -= 5; // Move backward by 10 seconds
        window.onBackwardMethod();
      }
      // Add more controls as needed
    };
  }, [assetId]);
  // function for getting the assset it from videoDetails
  const getAssetDetailsById = async (data) => {
    console.log("getAssetDetailsById:");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp.deviceType,
      slug: slug,
    };
    console.log("devicetype: ", headers.devicetype);
    console.log("token: ", token);
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    // code for fetching details via assetsId

    const config = {
      method: "post",
      url: movieBaseUrl + "getAssetIdByVideoId",
      headers: headers,
      data: data,
    };
    axios
      .request(config)
      .then((res) => {
        // set the asset Id here
        if (res.data.status) {
          const assetId = res.data.data.assetId;
          console.log("assetId:-- ", assetId);
          setAssestId(assetId);
          // generateAuthToken(assetId);
          const audioFileUrl = res.data.data?.audioUrl[0]?.file;
          console.log("audioFileUrl: ", audioFileUrl);
          const subTitleFileUrl = res.data.data?.subTitle[0]?.file;
          console.log("subTitleFileUrl: ", subTitleFileUrl);
          const transactionId = res?.data?.data?.transactionId;
          setTransactionId(transactionId);
          console.log("transactionId: ", transactionId);
          // send details to save in reducer
          const reducerDetails = {
            audioUrl: audioFileUrl,
            viewerShipId,
            viewerId,
            assetId,
            subTitle: subTitleFileUrl,
          };
          // save the details in reducer
          dispatch(getAudioDataSuccess(reducerDetails));
          const videoElement = document.getElementById("videoPlayer");
          if (videoElement) {
            setTimeout(() => {
              videoElement.pause();
            }, 500);
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  // get all the episode list
  const getEpisodes = async () => {
    console.log("getE");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp.deviceType,
      slug: slug,
    };
    console.log("token", token);
    console.log("deviceType", headers.devicetype);

    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `getVideoDetailsById/${videoId}`,
      headers: headers,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.data) {
          const data = res?.data?.data;

          setTitle(data.title);

          setIsSeries(data?.isSeries);

          var assetsData = {
            videoId: videoId,
          };
          if (data?.isSeries) {
            // const currentSeason = data?.seasons.length - 1;
            // const season = data?.seasons[currentSeason];
            const season = data?.seasons?.find(
              (item) => item?.seasonNumber === seasonNumber
            );
            console.log("getting Id for series");
            assetsData.seasonNumber = parseInt(seasonNumber);
            assetsData.episodeNumber = parseInt(selectedEpisode);
            console.log("season: ", season);
            // setSeasonNumber(season?.seasonNumber);
            setEpisodesList(season?.episodes);
            setPrice(season?.devicePricing);
            getAssetDetailsById(assetsData);
          } else {
            console.log("getting Id for movie");
            getAssetDetailsById(assetsData);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  // handle selcted epissode
  // const handleSelectEpisode = (episodeNumber) => {
  //   console.log("episodeNumber: ", episodeNumber);
  //   setSelectedEpisode(episodeNumber);
  //   // call the get AssetDetials here
  // };

  // getting the sink initiated for ppe if the primary device is yes
  const getSinkInitForPpePrimaryDevice = () => {
    //TODO change this code here to make it work with any browser window
    callHandlerCreate(viewerShipId);
  };

  // code here if the primary device is no  [ stop the video or whatever the case is  ]

  const getSinkInitForPpeNonPrimaryDevice = () => {
    // you can get the bluetooth status like above here this function will call when the type is ppe and primary device is no
    // you can get the bluetooth status like above here this function will call when the type is ppe and primary device is no
    callHandlerJoin(viewerShipId);
  };

  const isBluetoothConnected = async () => {
    // primary device - you will get the value yes or no in [primaryDevice] variable
    // get the sink code here
    let slug = await generateTokenHandler();
    var headers = {
      devicetype: deviceDetailsFromApp.deviceType,
      Authorization: token,
      slug: slug,
    };

    if (deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }

    const config = {
      url: movieBaseUrl + "getViewersList" + "/" + viewerShipId,
      method: "get",
      headers: headers,
    };

    // make the request here
    axios
      .request(config)
      .then((response) => {
        // const response = response.data
        // response will look like this
        //   {
        //     "status": true,
        //     "response_code": 200,
        //     "message": "Viewership details get successfully.",
        //     "data": [
        //         {
        //             "viewerId": "1viewerId234",
        //             "paymentStatus": "Complete",
        //             "viewerName": "Piryanshu Bistt",
        //               "BluetoothStatus":false
        //         },
        //         {
        //             "viewerId": "12viewerId34",
        //             "paymentStatus": "Incomplete",
        //             "viewerName": "Piryanshu"
        //               "BluetoothStatus":false
        //         },
        //         {
        //             "viewerId": "123viewerId4",
        //             "paymentStatus": "Complete",
        //             "viewerName": "Harsh Yadav"
        //               "BluetoothStatus":false
        //         }
        //     ]
        // }
        const respData = response.data;
        console.log(
          "response for bluetooth status api fetch : ",
          respData.data
        );
        var bluetoothFlag = true;
        if (!respData.status || respData.response_code != 200) return false;
        respData.data.map((item) => {
          bluetoothFlag = bluetoothFlag && item.BluetoothStatus;
        });
        return bluetoothFlag;
      })
      .catch((err) => {
        // error handling code
        console.log("Fetching bluetooth status failed");
        return false;
      });
  };

  // PPE specific code from Ashish

  function callHandlerCreate(viewershipId) {
    // use viewershipId as room and pd= primary device, with value r== right, w== wrong
    window.initApp23(viewershipId, syncBaseUrl, syncPort, isBluetoothConnected);
    window.callCreateRoom23(viewershipId);
  }

  function callHandlerJoin(viewershipId) {
    window.initApp23(viewershipId, syncBaseUrl, syncPort, null);
    window.callJoinRoom23(viewershipId);
  }

  const onError = (err) => {
    console.log(
      "Error during loading or playback of video...",
      JSON.stringify(err)
    );
    console.log("Error during loading or playback of video...", err.message);
  };
  // End of PPE specific code ***************** ==================== *******************

  const handleAbortVideo = async () => {
    console.log("Page Unmount");
    //
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp.deviceType,
      slug: slug,
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    // getting watchTime
    const watchTime = videoRef?.current?.currentTime;
    let data;
    if (isSeries) {
      data = {
        viewerId,
        viewerShipId,
        transactionId,
        videoId,
        watchTime,
        // will add transcationId
        seasonNumber,
        episodeNumber: selectedEpisode,
        isSeries,
      };
    } else {
      data = {
        viewerId,
        viewerShipId,
        transactionId,
        videoId,
        isSeries,
        watchTime,
        // will add transcationId
      };
    }

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `storeVideoAbortInfo`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
        if (res.data.status && res.data.status_code == 200) {
          console.log("Successfull Response", res.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        }
      });
  };
  const handleStartVideoInfo = async () => {
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp.deviceType,
      slug: slug,
    };
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    let data;
    const watchTime = document.getElementById("videoPlayer").currentTime;
    if (isSeries) {
      data = {
        viewerId,
        viewerShipId,
        location: parseCustomJsonString(location),
        transactionId,
        videoId,
        isSeries,
        seasonNumber: parseInt(seasonNumber),
        episodeNumber: parseInt(selectedEpisode),
        startTime: new Date(),
        watchTime: watchTime,
        // will add transcationId
      };
    } else {
      data = {
        viewerId,
        isSeries,
        viewerShipId,
        location: parseCustomJsonString(location),
        transactionId,
        videoId,
        startTime: new Date(),
        watchTime: watchTime,
        // will add transcationId
      };
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `storeVideoStartInfo`,
      headers: headers,
      data,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data);
        if (res.data.status && res.data.status_code == 200) {
          console.log("Successfull Response", res.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        }
      });
  };

  const handleCustomControler = (v) => {
    if (v == "play") {
      window.onPlayMethod();
      setShowPlayButton(true);
    } else if (v == "pause") {
      window.onPauseMethod();
      setShowPlayButton(false);
    } else if (v == "forward") {
      window.OnForwardMethod();
    } else if (v == "backward") {
      window.onBackwardMethod();
    }
  };

  const handleMouseMove = () => {
    setShowControls(true);
    if (controlTimeoutRef.current) {
      clearTimeout(controlTimeoutRef.current);
    }
    controlTimeoutRef.current = setTimeout(() => {
      setShowControls(false);
    }, 3000); // Adjust time as needed
  };

  const handleMouseLeave = () => {
    setShowControls(false);
    if (controlTimeoutRef.current) {
      clearTimeout(controlTimeoutRef.current);
    }
  };

  // Get Payment Status For Video

  const getPaymentStatus = async () => {
    console.log("get Payment status--------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: getDeviceType(),
      slug: slug,
    };
    console.log(headers.authorization);
    console.log(headers.devicetype);
    console.log(deviceDetailsFromApp);
    console.log(deviceDetailsFromApp?.deviceId);

    if (
      deviceDetailsFromApp &&
      (deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId)
    ) {
      headers.appid =
        deviceDetailsFromApp?.androidId || deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    console.log("isSeries", isSeries);
    let series = JSON.parse(sessionStorage.getItem("isSeries"));
    console.log("season Number Demo ", seasonNumber);

    let url =
      movieBaseUrl +
      "getPaymentStatusForVideo_v3" +
      `?videoId=${videoId}&isSeries=${series ? "true" : "false"}`;
    if (series) {
      url =
        movieBaseUrl +
        "getPaymentStatusForVideo_v3" +
        `?videoId=${videoId}&isSeries=${
          series ? "true" : "false"
        }&seasonNumber=${seasonNumber}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      // movieBaseUrl + "getPaymentStatusForVideo_v3" + `?videoId=${video_id}&isSeries=${series ?'true' : 'false'} ${series && "&seasonNumber=" + seasonNumber}`,
      headers: headers,
    };

    axios
      .request(config)
      .then((res) => {
        console.log("res: ", res.data.data);
        if (res.data.status && res.data.response_code == 200) {
          setPaymentStatus(res.data.data);
          // getToast(res.data.message, "top-right", "success");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  const handleChangeEpisode = (episodeNumber) => {
    let res = checkPaymentStatusForEpisodesInPPE(
      getDeviceType,
      paymentStatus,
      isSeries,
      seasonNumber,
      episodeNumber
    );
    if (res && res?.viewerShipId) {
      viewerShipId = res?.viewerShipId;
    } else {
      viewerShipId =
        window.viewerShipId || sessionStorage.getItem("viewerShipId");
    }
    handleSelectEpisode(episodeNumber);
    setSelectedEpisode(episodeNumber);
    sessionStorage.setItem("episodeNumber", episodeNumber);
    // call the get AssetDetials here
  };

  return (
    <div>
      <div id="player" style={{ width: "100%" }}></div>
      <div>
        <div className={styles.media_frame}>
          {/* if user has select mobile (ppe) so we will show the video and audio in same device  */}
          {primaryDevice == "yes"
            ? playerSrc && (
                <div className={styles.video_player_container}>
                  <div
                    className={styles.custom_controller_box}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  >
                    {!loadingForVideo ? (
                      <ReactDRMPlayer
                        src={playerSrc}
                        muted
                        preload="none"
                        id="videoPlayer"
                        controls
                        widevineLicenseURI={widevineLicenseURI}
                        onPlayerError={(error) => {
                          onError(error);
                        }}
                        className={styles.media_player}
                        onPlaybackError={(error) => {
                          onError(error);
                        }}
                      />
                    ) : (
                      <div className={styles.media_frame_loader}>
                        {" "}
                        <FullScreenLoader />{" "}
                      </div>
                    )}

                    <div
                      className={styles.controls_box}
                      style={{ display: showControls ? "flex" : "none" }}
                      onTouchMove={() => {
                        setShowControls(true);
                      }}
                    >
                      <div className={styles.controls}>
                        <div
                          className={styles.backward}
                          onClick={() => {
                            handleCustomControler("backward");
                          }}
                        >
                          <img src={backwardIcon} alt="backward-icon" />
                        </div>
                        <div
                          className={styles.play}
                          style={{ display: showPlayBtn ? "none" : "block" }}
                          onClick={() => handleCustomControler("play")}
                        >
                          <img src={playIcon} alt="play-icon" />
                        </div>
                        <div
                          className={styles.pause}
                          style={{ display: !showPlayBtn ? "none" : "block" }}
                          onClick={() => handleCustomControler("pause")}
                        >
                          <img src={pauseIcon} alt="pause-icon" />
                        </div>
                        <div
                          className={styles.forward}
                          onClick={() => {
                            handleCustomControler("forward");
                          }}
                        >
                          <img src={forwardIcon} alt="forward-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* custom controls for video */}
                  <Controls
                    id="videoPlayer"
                    isPlaying={!document.getElementById("videoPlayer")?.paused}
                  />
                  <div className={styles.player_btn_container}>
                    {/* <button id="play_button" className={styles.player_btn}>
                      Play
                    </button>
                    <button id="pause_button" className={styles.player_btn}>
                      Pause
                    </button> */}
                  </div>
                </div>
              )
            : audioUrl && (
                <div className={styles.audio_screen}>
                  <h2>{title}</h2>
                  <p>{t("Get theatre like experience at home with Hall IV")}</p>

                  <video
                    id="videoPlayer"
                    preload="auto"
                    // style={{ visibility: "hidden" }}
                    src={audioUrl}
                    // controls

                    ref={videoRef}
                    onPlay={handleStartVideoInfo}
                    onAbort={handleAbortVideo}
                    onPause={handleStartVideoInfo}
                  >
                    Your browser does not support the audio element.
                  </video>
                  <img src={headphones} alt="" />
                  <h3>{t("Audio is Playing")}</h3>

                  {/* <p className={styles.bluetooth_status}>
                    {t("Bluetooth is ")} {bluetoothStatus}
                  </p> */}
                </div>
              )}

          {}
        </div>
        {/* <div className={styles.player_btn_container}>
          <button id="play_button" className={styles.player_btn}>
            Play
          </button>
          <button id="pause_button" className={styles.player_btn}>
            Pause
          </button>
        </div> */}
        {/* if it is a series then show here the list of episodes */}
        {isSeries && paymentStatus ? (
          <div className={styles.episodes_list}>
            {episodesList
              ?.filter((items) =>
                checkPaymentStatusForEpisodesInPPE(
                  getDeviceType,
                  paymentStatus,
                  isSeries,
                  seasonNumber,
                  items?.episodeNumber
                )
              )
              ?.sort((a, b) => a.episodeNumber - b.episodeNumber) // Sort episodes by episode number
              ?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className={
                      selectedEpisode == el.episodeNumber
                        ? styles.selected_episode
                        : ""
                    }
                    onClick={() => handleChangeEpisode(el.episodeNumber)}
                  >
                    <div>
                      <img src={el?.thumbnail?.file} alt="" />
                    </div>
                    <div>
                      <h3>{el?.title}</h3>
                      <div className={styles.episodes_details_header}>
                        <span>
                          {" "}
                          {Math.floor((el.duration || 0) / 60) +
                            "." +
                            Math.floor((el.duration || 0) % 60) +
                            " " +
                            "min"}
                        </span>

                        <span>Episode - {el?.episodeNumber}</span>
                      </div>
                      <p>{el?.synopsis}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
