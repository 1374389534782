export const GET_DATA_REQ = "GET_DATA_REQ";
export const GET_DATA_SUCC = "GET_DATA_SUCC";
export const GET_DATA_ERR = "GET_DATA_ERR";

export const GET_DATA_REQ_BY_ID = "GET_DATA_REQ_BY_ID";
export const GET_DATA_SUCC_BY_ID = "GET_DATA_SUCC_BY_ID";
export const GET_DATA_ERR_BY_ID = "GET_DATA_ERR_BY_ID";

export const GET_WALLET_DETAILS_SUCC = "GET_WALLET_DETAILS_SUCC";
export const GET_WALLET_DETAILS_ERR = "GET_WALLET_DETAILS_ERR";
export const GET_SEASON_NUMBER = "GET_SEASON_NUMBER";
