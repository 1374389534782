import React, { useEffect } from "react";
import { injectMonetagScript } from "../../utils/adhelper";

const MonetagAd = ({ getDeviceType }) => {
  useEffect(() => {
    // Inject the script when the component mounts
    injectMonetagScript();
  }, []);

  const handleShowAd = () => {
    // Make the ad container visible or trigger any ad-related functionality
    const adContainer = document.getElementById("monetag-ad-container");
    if (adContainer) {
      adContainer.style.display = "block"; // Ensure the container is visible
    }
  };

  return (
    <div>
      <h1>Monetag Ad Integration</h1>
      <button onClick={handleShowAd}>Show Ad</button>
      {/* This container will hold the ad */}
      <div
        id="monetag-ad-container"
        style={{
          width: "300px",
          height: "250px",
          display: "none", // Initially hidden
          backgroundColor: "#f0f0f0", // Placeholder background
        }}
      ></div>
    </div>
  );
};

export default MonetagAd;
