import React, { useEffect, useState } from "react";
import { Placeholder, Stack } from "react-bootstrap";

const CarouselSkeleton = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemCount, setitemsCount] = useState([]);

  useEffect(() => {
    function handleResize() {
      console.log("window width", window.innerWidth);
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 1581) {
        setitemsCount(Array.from({ length: 8 }));
      } else if (window.innerWidth >= 1201 && window.innerWidth <= 1580) {
        setitemsCount(Array.from({ length: 6 }));
      } else if (window.innerWidth >= 1025 && window.innerWidth <= 1200) {
        setitemsCount(Array.from({ length: 5 }));
      } else if (window.innerWidth >= 801 && window.innerWidth <= 1024) {
        setitemsCount(Array.from({ length: 4 }));
      } else if (window.innerWidth >= 481 && window.innerWidth <= 800) {
        setitemsCount(Array.from({ length: 2 }));
      } else if (window.innerWidth >= 100 && window.innerWidth <= 480) {
        setitemsCount(Array.from({ length: 1 }));
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "6px",
      }}
    >
      {/* Skeleton for the carousel title */}
      <Placeholder animation="glow" className="mb-3">
        <Placeholder
          xs={12}
          size="lg"
          style={{ backgroundColor: "#444", height: "40px" }}
        />
      </Placeholder>

      {/* Horizontal scrollable container */}
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "15px",
        }}
      >
        {/* Skeleton items */}
        {itemCount?.map((_, index) => (
          <Stack
            key={index}
            style={{
              minWidth: "150px",
              height: "160px",
              backgroundColor: "#333",
              borderRadius: "10px",
            }}
          >
            {/* Skeleton image */}
            <div style={{ flex: 1, borderRadius: "8px", overflow: "hidden" }}>
              <Placeholder animation="glow">
                <Placeholder
                  // as="div"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#777",
                    //   backgroundColor: "#666", // Updated color for visibility
                  }}
                  xs={6}
                />
              </Placeholder>
            </div>
            {/* Skeleton text */}
            <div style={{ overflow: "hidden" }}>
              <Placeholder
                animation="glow"
                className="mt-2"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  overflow: "hidden",
                }}
              >
                <Placeholder
                  xs={5}
                  style={{
                    backgroundColor: "#777",
                    overflow: "hidden",
                    borderBottomRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                    height: "30px",
                  }}
                />
              </Placeholder>
            </div>
          </Stack>
        ))}
      </div>
    </div>
  );
};

export default CarouselSkeleton;
