import React from "react";
import styles from "./SeriesHeader.module.css";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { getSeasonNumber } from "../../../redux/dataReducer/action";

const SeriesHeader = ({ seasonsNumber, setSeasonsNumber, totalSeason }) => {
  let dispatch = useDispatch();
  function changeSeasonNumber(e) {
    setSeasonsNumber(e);
    dispatch(getSeasonNumber(e));
  }
  return (
    <div className={styles.container}>
      <div className={styles.containerSelectBox}>
        {totalSeason === 1 ? (
          <div
            className={`${styles.option} ${
              totalSeason === 1 ? styles.singleSeason : ""
            }`}
          >
            Season 1
          </div>
        ) : (
          <Form.Select
            size="lg"
            className={`${styles.option} ${
              totalSeason === 1 ? styles.singleSeason : ""
            }`}
            value={seasonsNumber}
            onChange={(e) => changeSeasonNumber(e.target.value)}
            disabled={totalSeason === 1}
          >
            {Array.from({ length: totalSeason }, (_, index) => (
              <option key={index} value={index + 1}>
                Season {index + 1}
              </option>
            ))}
          </Form.Select>
        )}
      </div>
    </div>
  );
};

export default SeriesHeader;
