export const getPaymentDevice = (paymentStatusDoc, getDeviceType, type) => {
  console.log("device types ssssssssssssss", getDeviceType());
  console.log("Itme Device type ", paymentStatusDoc?.deviceType);

  if (
    paymentStatusDoc?.payDevice == "tv" &&
    (paymentStatusDoc?.priceType == "watchParty" ||
      paymentStatusDoc?.priceType == "watchPartySeason" ||
      paymentStatusDoc?.priceType == "watchPartyPerEpisode") &&
    (getDeviceType() == "mobile" ||
      getDeviceType() == "laptop" ||
      getDeviceType() == "tv")
  ) {
    return true;
  } else if (
    paymentStatusDoc?.payDevice == "laptop" &&
    (paymentStatusDoc?.priceType == "watchParty" ||
      paymentStatusDoc?.priceType == "watchPartySeason" ||
      paymentStatusDoc?.priceType == "watchPartyPerEpisode") &&
    (getDeviceType() == "mobile" || getDeviceType() == "laptop")
  ) {
    return true;
  } else if (
    paymentStatusDoc?.payDevice == "mobile" &&
    (paymentStatusDoc?.priceType == "watchParty" ||
      paymentStatusDoc?.priceType == "watchPartySeason" ||
      paymentStatusDoc?.priceType == "watchPartyPerEpisode") &&
    getDeviceType() == "mobile"
  ) {
    return true;
  }
  return false;
};

export const checkPaymentStatusForSeasonOrMovies = (
  getDeviceType,
  paymentStatus,
  isSeries,
  seasonNumber
) => {
  let res = paymentStatus?.docs?.find(
    (item) =>
      (item?.priceType == "watchPartySeason" ||
        item?.priceType == "watchParty") &&
      (isSeries
        ? item?.seasonNumber == seasonNumber && item?.episodeNumber == null
        : true) &&
      getPaymentDevice(item, getDeviceType)
  );

  if (res) {
    return res;
  } else {
    return null;
  }
};

export const checkPaymentStatusForEpisodes = (
  getDeviceType,
  paymentStatus,
  isSeries,
  seasonNumber,
  episodeNumber
) => {
  let res = paymentStatus?.docs?.find(
    (item) =>
      (item?.priceType == "watchPartySeason" ||
        item?.priceType == "watchParty" ||
        item?.priceType == "watchPartyPerEpisode") &&
      item?.seasonNumber == seasonNumber &&
      (item?.episodeNumber == null || item?.episodeNumber === episodeNumber) &&
      getPaymentDevice(item, getDeviceType)
  );

  if (res) {
    return res;
  } else {
    return null;
  }
};

export const getPaymentDevicePPE = (paymentStatusDoc, getDeviceType, type) => {
  console.log("device types ssssssssssssss", getDeviceType());
  console.log("Itme Device type ", paymentStatusDoc?.deviceType);

  if (
    paymentStatusDoc?.payDevice == "tv" &&
    (paymentStatusDoc?.priceType == "ppe" ||
      paymentStatusDoc?.priceType == "ppeSeason" ||
      paymentStatusDoc?.priceType == "ppePerEpisode") &&
    (getDeviceType() == "mobile" ||
      getDeviceType() == "laptop" ||
      getDeviceType() == "tv")
  ) {
    return true;
  } else if (
    paymentStatusDoc?.payDevice == "laptop" &&
    (paymentStatusDoc?.priceType == "ppe" ||
      paymentStatusDoc?.priceType == "ppeSeason" ||
      paymentStatusDoc?.priceType == "ppePerEpisode") &&
    (getDeviceType() == "mobile" || getDeviceType() == "laptop")
  ) {
    return true;
  } else if (
    paymentStatusDoc?.payDevice == "mobile" &&
    (paymentStatusDoc?.priceType == "ppe" ||
      paymentStatusDoc?.priceType == "ppeSeason" ||
      paymentStatusDoc?.priceType == "ppePerEpisode") &&
    getDeviceType() == "mobile"
  ) {
    return true;
  }
  return false;
};

export const checkPaymentStatusForSeasonOrMoviesInPPE = (
  getDeviceType,
  paymentStatus,
  isSeries,
  seasonNumber
) => {
  let res = paymentStatus?.docs?.find(
    (item) =>
      (item?.priceType == "ppe" || item?.priceType == "ppeSeason") &&
      (isSeries
        ? item?.seasonNumber == seasonNumber && item?.episodeNumber == null
        : true) &&
      getPaymentDevicePPE(item, getDeviceType)
  );

  if (res) {
    return res;
  } else {
    return null;
  }
};

export const checkPaymentStatusForEpisodesInPPE = (
  getDeviceType,
  paymentStatus,
  isSeries,
  seasonNumber,
  episodeNumber
) => {
  let res = paymentStatus?.docs?.find(
    (item) =>
      (item?.priceType == "ppe" ||
        item?.priceType == "ppeSeason" ||
        item?.priceType == "ppePerEpisode") &&
      item?.seasonNumber == seasonNumber &&
      (item?.episodeNumber == null || item?.episodeNumber === episodeNumber) &&
      getPaymentDevicePPE(item, getDeviceType)
  );

  if (res) {
    return res;
  } else {
    return null;
  }
};
