import React from "react";
import { Placeholder } from "react-bootstrap";
import styles from "./BannerSkeleton.module.css"; // Import the CSS Module

export const BannerSkeleton = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <Placeholder animation="glow" className="mb-3">
        <Placeholder
          xs={12}
          size="lg"
          className={styles.bannerPlaceholder} // Use CSS Module class
        />
      </Placeholder>
    </div>
  );
};
