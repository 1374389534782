const renderEpisodesInCarousel = (videos) => {
  let transformData = [];

  // Loop through each video
  videos?.forEach((video) => {
    if (video?.isSeries && video?.seasons?.episodes?.length >= 1) {
      // Loop through each episode in the series
      video?.seasons?.episodes.forEach((episode) => {
        transformData.push({
          videoId: video?.videoId,
          _id: video?._id,
          isSeries: video?.isSeries,
          keyArt1: episode?.thumbnail, // Background image for the episode
          seasons: { seasonNumber: video?.seasons?.seasonNumber }, // Season info
          category: video?.category, // Category of the video
          title: episode?.title, // Title of the episode
        });
      });
    } else {
      transformData?.push(video);
    }
  });

  return transformData;
};

export default renderEpisodesInCarousel;
